  <template>
  <v-app>
    <v-app-bar app color="primary" dark v-if="$store.state.initialized">
      <div class="d-flex align-center">
        <router-link :to="homePath">
          <v-img
            alt="App Icon"
            class="shrink mr-4"
            contain
            src="/assets/forecast-menubarIcon.svg"
            transition="scale-transition"
            width="28"
          />
        </router-link>
        <div class="appTitle">
          {{ appTitle }}
        </div>
      </div>
<v-spacer></v-spacer>
        <ForecastSelector />
      <v-spacer></v-spacer>
      <v-tooltip bottom open-delay="300">
        <template v-slot:activator="{ on, attrs }">
        <v-btn 
        @click="testfest" 
        icon 
        v-bind="attrs"
        v-on="on"
        >
        <v-icon>mdi-message-alert</v-icon>
        </v-btn>
        </template>
        <span>{{ trl('menu_testfest') }}</span>
      </v-tooltip>
      <v-btn 
        v-if="current.permissions && current.permissions.TENANTS_MANAGE"
        class="mr-4"
        to="/admin"
        icon 
        >
        <v-icon>mdi-shield-crown-outline</v-icon>
        </v-btn>
       <Me />
    </v-app-bar>

    <v-main>
      <router-view :key="$store.state.activeForecastId" />
      <Snackbar />
      <AlertDialog />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { testfest } from "@/utils/testfest";
import { trl } from "@/utils/strings";
import ForecastSelector from "@/components/ForecastSelector.vue";
import Me from "@/components/Me.vue";
import Snackbar from "@/components/Snackbar.vue";
import AlertDialog from "@/components/modals/Alert.vue";

export default {
  name: "App",
  components: { Me, Snackbar, AlertDialog, ForecastSelector },

  data() {
    return {
      dialogs: {
        logout: false, 
      }
    };
  },
  computed: {
    appTitle: function () {
      return process.env.VUE_APP_TITLE + ' ' + (this.current.name || process.env.VUE_APP_VERSION);
    },
    homePath(){
        if (this.current.path) {
          return `/${this.current.path}/main`;
        }
        return '/main';
    },
    ...mapGetters(["current", "forecasts"])
  },
  methods: {
    trl,
    testfest
  },
  created() {
    // auto-login bei vorhandenem Token
    let token = localStorage.getItem("token");
    if (token) {
      this.$store.dispatch("auth", { token: token, login: true, rememberMe: true });
    }
  }
};
</script>
<style scoped>
.appTitle {
  color: #fff;
  text-decoration: none !important;
  cursor: default;
  font-size: 24px;
  font-weight: bold;
}
</style>