import store from '../store'

export function testfest() {

    let tf_id = 443,
        tf_w = 580,
        tf_h = 700,
        left = screen.width / 2 - tf_w / 2,
        top = screen.height / 2 - tf_h / 2;

    let params =
        "pid=" + tf_id +
        "&url=" + encodeURIComponent(window.location.href) +
        "&title=" + encodeURIComponent(document.title) +
        "&screen_window_wxh=" + window.innerWidth + "x" + window.innerHeight +
        "&screen_wxh=" + screen.width + "x" + screen.height + 
        "&email=" + store.state.me.email + 
        "&name=" + encodeURIComponent(store.state.me.firstname + " " + store.state.me.lastname)

    window.open(
        "https://testfest.crossdigital.de/frontend/?" + btoa(params),
        "Test Fest Feedback",
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, " +
        "width=" + tf_w + "px, " + 
        "height=" + tf_h + "px, " +
        "top=" + top + ", " +
        "left=" + left
    );
}
