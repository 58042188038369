import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Auth from '../views/Auth.vue'
import Main from '../views/Main.vue'
import NotFound from '../views/NotFound.vue'
import Logout from '../components/Logout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Auth,
    beforeEnter: (to, from, next) => {
      if (store.getters.me.id) {
        next('/main');
      } else {
        next();
      }
    }
  },
  {
    path: '/main',
    name: 'Main',
    component: Main,
    children:[{
      path: '/:tenant/main',
      name: 'TenantHome',
      component: Main
    }]
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {label: 'Admin'},
    component: () => import('../views/Admin/Home.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters.current.permissions.TENANTS_MANAGE) {
        next();
      } else {
        next('/Main');
      }
    },
    children: [
      {
        path: '/admin/stats',
        name: 'Stats',
        meta: {label: 'Nutzungsstatistiken'},
        component: () => import('../views/Admin/Stats.vue'),
      },
      {
        path: '/admin/tenants',
        name: 'Tenants',
        meta: {label: 'Mandantenverwaltung'},
        component: () => import('../views/Admin/Tenants.vue'),
        children: [
          {
            path: '/admin/tenants/:id',
            name: 'TenantForm',
            meta: {label: ''},
            component: () => import('../components/forms/Tenant.vue'),
          }
        ]
      },
      {
        path: '/admin/roles',
        name: 'Roles2',
        meta: {label: 'Benutzerrollen'},
        component: () => import('../views/Admin/Roles.vue'),
        children: [
          {
            path: '/admin/roles/:id',
            name: 'RoleForm',
            meta: {label: ''},
            component: () => import('../components/forms/Role.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/:tenant/users',
    name: 'Users',
    component: () => import('../views/Users.vue')
  },
  {
    path: '/:tenant/users/:id',
    name: 'UsersDetail',
    component: () => import('../views/Users.vue')
  },
  {
    path: '/:tenant/divisions',
    name: 'Divisions',
    component: () => import('../views/Divisions.vue'),
    children: [{
        path: '/:tenant/divisions/:id',
        name: 'Details',
        component: () => import('../components/Division.vue'),

      },
      {
        path: '/:tenant/divisions/:id/edit',
        name: 'DivisionEdit',
        component: () => import('../components/forms/Division.vue')
      },
      {
        path: '/:tenant/divisions/new',
        name: 'DivisionForm',
        component: () => import('../components/forms/Division.vue')
      }
    ]
  },
  {
    path: '/:tenant/clients',
    name: 'Clients',
    component: () => import('../views/Clients.vue')
  },
  {
    path: '/:tenant/vendors',
    name: 'Vendors',
    component: () => import('../views/Vendors.vue'),
    children: []
  },
  {
    path: '/:tenant/forecasts',
    name: 'Forecasts',
    component: () => import('../views/Forecasts.vue')
  },
  {
    path: '/:tenant/attributes',
    name: 'Attributes',
    component: () => import('../views/Attributes.vue'),
    children: [{
      path: '/:tenant/attributes/:id',
      name: 'AttributesForm',
      component: () => import('../components/forms/Attribute.vue'),
    }]
  },
  {
    path: '/:tenant/budget',
    name: 'Budget',
    component: () => import('../views/Budget.vue'),
    children: [{
      path: '/:tenant/budget/:id',
      name: 'BudgetDivision',
      component: () => import('../components/Budget.vue'),
    }]
  },
  {
    path: '/:tenant/explore',
    name: 'Explorer',
    component: () => import('../views/Explorer.vue')
  },
  {
    path: '/:tenant/progress/',
    name: 'Progress',
    component: () => import('../views/Progress.vue')
  },
  {
    path: '/:tenant/compare/',
    name: 'Compare',
    component: () => import('../views/Compare.vue')
  },
  {
    path: '/:tenant/costs',
    name: 'Costs',
    component: () => import('../views/Costs.vue'),
    children: [{
      path: '/:tenant/costs/:snapshot',
      name: 'CostsOverview',
      component: () => import('../components/Costs.vue')
    },{
      path: '/:tenant/costs/vendors/:id',
      name: 'SingleVendor',
      component: () => import('../components/SingleVendor.vue')
    }]
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (!store.getters.me.id && to.name !== 'Home') {
    store.commit("redirectFromLogin", to)
    next({
      name: 'Home'
    });
  } else {
    document.title = `${process.env.VUE_APP_TITLE} ${process.env.VUE_APP_VERSION} - ${to.name}`;
    next();
  }
})

export default router