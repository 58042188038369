import store from '../store'
import { DateTime } from "luxon";


let formats = {
    short: {
        ...DateTime.DATETIME_SHORT,
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      },
      medium: DateTime.DATETIME_MED,
    dateshort: {
        ...DateTime.DATE_SHORT,
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
    }
}

export function d2s(timestamp, format) { // date 2 ("to") string

    if (!timestamp) return "---";
    let suffix = ["short", "medium"].includes(format) ? " Uhr" : "";

    return DateTime.fromSQL(timestamp, { zone: process.env.VUE_APP_SQL_TZ })
        .setZone(store.state.me.timezone)
        .setLocale(store.state.me.locale)
        .toLocaleString(formats[format]) + suffix;
}

export function isLocked(division, year, ignore) {
    // ignore: "past" || "future"
    if (!division.fy_first && !division.fy_last) return false;
    if (division.fy_first && division.fy_first > year && (!ignore || ignore === "past")) return true;
    if (division.fy_last && division.fy_last < year && (!ignore || ignore === "future")) return true;

    return false;
}