<template>
  <v-container>
      <h1>Nicht gefunden</h1>
      <a @click="$router.go(-1)">zurück</a> | 
      <router-link to="/main">zur Übersicht</router-link>
  </v-container>
</template>

<script>
export default {

}
</script>

<style scoped>


.container{
    text-align: center;
    padding-top:3rem;
}

a{
    text-decoration: underline;
}

</style>