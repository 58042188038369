<template>
  <div class="text-center">
    <v-snackbar
      v-model="$store.state.ui.snackbar.show"
      :vertical="$store.state.ui.snackbar.vertical"
      :timeout="$store.state.ui.snackbar.timeout"
      :color="$store.state.ui.snackbar.color"
      right
    >
      {{ $store.state.ui.snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-icon
          text
          v-bind="attrs"
          @click="dismiss"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
    methods:{
        dismiss() {
            this.$store.commit("toggleSnackbar", {show: false, text: "", timeout: 0})
        }
    }
  }
</script>

<style>

</style>