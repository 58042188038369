<template>
  <div class="grid" :class="{ login: !$store.state.me.id }">
    <div id="auth">
      <h1>{{ headline }} </h1>
      <v-btn
        large
        color="primary"
        elevation="3"
        type="button"
        @click="popupCenter('/api/xma/', 'CROSSMEDIA Accounts', 395, 620)"
      >
        <span color="#fff">Login mit XM Accounts</span>
      </v-btn>
    </div>
    <svg class="spinner" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
  </div>
</template>

<script>
export default {
  name: "Auth",
  data() {
    return {
      popup: null,
      headline: process.env.VUE_APP_TITLE + " " + process.env.VUE_APP_VERSION,
    };
  },
  methods: {
    popupCenter(url, title, w, h) {
      let left = screen.width / 2 - w / 2;
      let top = screen.height / 2 - h / 2;
      this.popup = window.open(
        url,
        title,
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
          w +
          "px, height=" +
          h +
          "px, top=" +
          top +
          ", left=" +
          left
      );
    },

    async auth(obj) {
      this.popup.close();

      try {
        const res = await this.$store.dispatch("auth", obj);

        if (!res.ok) {
          this.headline = res.status + " | " + res.statusText;
        }
      } catch (err) {
        console.log (err);
        this.headline = "Could not authenticate.";
      }
    },
  },
  created() {
    functionOutsideVue(this);
  },
};

let VueInstance;
window.functionOutsideVue = function (vuecomponent) {
  VueInstance = vuecomponent;
};
window.oauthBridge = function (obj) {
  window.setTimeout(() => VueInstance.auth(obj), 1000);
};
</script>
<style lang="scss" scoped>
.grid {
  display: grid;
  place-content: center;
  min-height: 100vh;
  text-align: center;
  margin: 0;
  overflow: hidden;
}

.login {
  background-image: url("/assets/mood2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;

  h1 {
    margin: 1rem 3rem;
    font-size: 56px;
    text-shadow: #222;
    text-shadow: 1px 1px 1px #000;
  }

  #auth {
    background-color: rgba(255, 255, 255, 0.35);
    backdrop-filter: blur(6px);
    padding: 2rem 3rem 3rem 3rem;
    border-radius: 8px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    display: block;
  }
  .spinner{
    display: none;
  }
}

#auth {
  display: none;
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 100px;
  height: 100px;
}

.spinner .path {
  stroke: hsla(0, 0%, 35%, 0.67);
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>