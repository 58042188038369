<template>
<div style="width:240px">
  <v-select
    v-if="!$store.state.ui.hideForecastSelector && forecasts.filter(f => f.vis).length > 1"
    v-model="fcid"
    :items="forecasts.filter(f => f.vis).sort((a,b) => a.fy - b.fy)"
    item-text="title"
    item-value="id"
    class="mt-4"
    @change="setForecast"
  >
  
  </v-select>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
    data() {
        return {
           fcid: null,
        }
    },
  computed: {
    ...mapGetters(["forecasts"]),
    ...mapState(["activeForecastId"]),
  },
  methods: {
    setForecast() {
      this.$store.commit("setForecast", this.fcid)
    }
  },
  created() {
    this.fcid = this.activeForecastId
  },
  watch:{
    $route (to, from){
        //console.log("route has changed");
    },
    activeForecastId(val){
      this.fcid = val
    }
} 
};
</script>

<style lang="scss" scoped>
::v-deep .v-select__selection{
  width:100%;
  text-align:center;
  font-size:120%;
  padding-left:28px;
}
</style>