import store from '../store'

function numerus(str, val, zeroString) {

    if (val == 0 && typeof zeroString !== 'undefined')
        return zeroString;

    str = str.replace("?", val);
    let words = str.split(" ");

    for (let i = 0; i < words.length; i++) {
        let split;
        split = words[i].split('||');

        if (split.length === 2) {
            words[i] = val == 1 ? split[0] : split[1];
            continue;
        }

        split = words[i].split('|');

        if (split.length === 1) continue;

        if (val == 1) split.pop();
        else if (split.length === 3) split.splice(1, 1);

        words[i] = split.join('');
    }

    return words.join(" ");
}

function trl() {
    let strings = store.state.ui.strings
    let newstr = arguments[0];

    if (newstr in strings) {
        newstr = strings[newstr]; //decodeURIComponent(strings[newstr]).replace(/\+/g, " ");
        newstr = newstr.replace(/\\n/g, '\n');
        for (let i = 1; i < arguments.length; i++) {
            let re = new RegExp("\\{\\{" + i + "\\}\\}", "gi");
            newstr = newstr.replace(re, arguments[i]);
        }
    }

    if (!newstr) return newstr;

    let matches = newstr.match(/numerus\(([^\)]*)\)/g);

    if (matches === null) {
        return newstr;
    }

    for (let i = 0; i < matches.length; i++) {
        newstr = newstr.replace(matches[i], eval(matches[i]));
    }

    return newstr;
}

function smartjoin(arrInput, glue) {

    const arr = [...arrInput];

    if (!arr.length) return "";

    if (arr.length === 1) {
        return arr[0];
    }

    if (!glue) {
        return arr.join(", ");
    }

    let last = arr.pop();

    if (arr.length > 0) {
        return `${arr.join(", ")} ${glue} ${last}`;
    }
}

function strellipsis(str, length) {
    if (str.length < (length + 4)) return str;
    return '<span title="' + str + '">' + str.substr(0, length/2) + '…' + str.substr(length/2*-1) + '</span>';
}

function ls_get(str, defaultObject) {
    return localStorage.getItem(str) ? JSON.parse(localStorage.getItem(str)) : defaultObject;
}

function ls_set(str, obj) {
    localStorage.setItem(str, JSON.stringify(obj));
}

function getClientName(id) {
    return store.getters.clients.find((item) => item.id === id)?.name || `UNKNOWN CLIENT (${id})`;
}

function getDivisionName(id) {
    return store.getters.divisions.find((item) => item.id === id)?.name || `UNKNOWN DIVISION (${id})`;
}

function getAttributeName(id) {
    return store.getters.attributes.find((item) => item.id === id)?.value || `UNKNOWN ATTRIBUTE (${id})`;
}

function getStatus(num){
    return this.$store.state.budget.status.find((item) => item.value === num);
}



export { numerus, trl, strellipsis, smartjoin, ls_get, ls_set, getClientName, getDivisionName, getAttributeName, getStatus };