import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import Vuetify from '../plugins/vuetify'
import router from '../router'
import {
  trl
} from '../utils/strings'

import { DateTime } from 'luxon';
import { isLocked } from '@/utils/dates';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    initialized: false,
    redirect: false, // temporarily holds requested route if user is sent to login route
    me: {},
    activeTenant: {},
    users: [],
    divisions: [],
    clients: [],
    newBusiness: [],
    vendors: [],
    permissions: [],
    roles: [],
    forecasts: [],
    activeForecastId: null,
    attributes: [],
    budget:{
      fields: [
        {key: "kundennetto", suffix: "T&euro;", calculated: false, sumup:true, round: 2, endOfGroup: false, label: null }, // labels werden in mutation "setLocale" gesetzt
        {key: "ae", suffix: "%", calculated: false, sumup:false, round: 2, endOfGroup: false, label: null },
        {key: "agenturnetto", suffix: "T&euro;", calculated: true, sumup:true, round: 2, endOfGroup: true, label: null },
        {key: "honorar_basis_percent", suffix: "%", calculated: false, sumup:false, round: 2, endOfGroup: false, label: null },
        {key: "honorar_basis", suffix: "T&euro;", calculated: true, sumup:true, round: 2, endOfGroup: false, label: null },
        {key: "honorar_sokos", suffix: "T&euro;", calculated: false, sumup:true, round: 2, endOfGroup: true, label: null },
        {key: "income_other", suffix: "T&euro;", calculated: false, sumup:true, round: 2, endOfGroup: true, label: null },
        {key: "total", suffix: "T&euro;", calculated: true, sumup:true, round: 2, endOfGroup: false, label: null },
        {key: "fremdkosten", suffix: "T&euro;", calculated: false, sumup:true, round: 2, endOfGroup: false, label: null },
        {key: "total_wo_fk", suffix: "T&euro;", calculated: true, sumup:true, round: 2, endOfGroup: true, label: null },
      ],
      status: [
        {
          value: 0,
          label: "unsicher",
          color: "pink darken-2",
          hex: "#b22d5b",
          icon: "mdi-circle"
        },
        {
          value: 1,
          label: "Planbudget",
          color: "amber darken-2",
          hex: "#f2a33a",
          icon: "mdi-circle"
        },
        {
          value: 2,
          label: "freigegeben",
          color: "teal darken-2",
          hex: "#34786b",
          icon: "mdi-circle"
        }
      ],
      costs: [
        "INT",
        "EXT"
      ],
      commits: {
        days_in_advance: 10,
        time_on_due_date: '11:30:00 UTC' // <--- always UTC
      }
    },
    ui: {
      hideForecastSelector: false,
      theme: {
        primary: "#666666",
        secondary: "#333333",
        accent: "#333333",
        anchor: "#0c2293",
        info: "#FBEEAC",
        success: "#4CAF50",
        warning: "#FFC107",
        error: "#FF5252",
      },
      snackbar: {
        show: false,
        text: '',
        vertical: false,
        timeout: 5000
      },
      alert: {
        show: false,
        title: '',
        text: ''
      },
      strings: {}
    },
    explorer: {
      reports: []
    }
  },
  mutations: {
    redirectFromLogin(state, to) {
      state.redirect = to;
    },
    initialized(state, value){
      state.initialized = value;
      this.commit("setTheme", state.ui.theme)
    },
    login(state, payload) {
      localStorage.setItem("token", payload.token);
      state.me = payload;
      state.me.avatar = process.env.VUE_APP_AVATAR_URL + "/" + state.me.picture;
    },
    logout(state) {
      localStorage.removeItem("token");
      state.me = {};
      state.activeTenant = {};
      state.initialized = false;
      this.commit("setTheme", state.ui.theme);
      router.push('/');
    },
    setLocale(state, payload) {
      state.ui.strings = payload
      state.budget.fields.forEach(f => f.label = trl('budget_' + f.key));
    },
    setTenant(state, tenantId) {
      
      if (!tenantId) {
        localStorage.removeItem("tenant");
        this.commit("setTheme", state.ui.theme);
        state.activeTenant = {};
        state.explorer.reports = [];
        return;
      }

      state.activeTenant = state.me.tenants.find(r => r.id === tenantId);
      localStorage.setItem("tenant", state.activeTenant.id);
      this.commit("setTheme", state.activeTenant.theme);
      state.explorer.reports = [];
      this.commit("findDefaultForecast");
    },
    setTheme(state, theme){
      if (!theme) theme = state.ui.theme;
      Vuetify.framework.theme.themes.light = theme;
    },
    findDefaultForecast({state}) {
      let defaultYear = localStorage.getItem("fy") || new Date().getFullYear();
      let activeForecasts = this.getters.forecasts.filter(f => f.active && f.vis && f.fy == defaultYear);
      if (activeForecasts.length > 0) {
        this.commit("setForecast", activeForecasts[0].id);
      } else {
        activeForecasts = this.getters.forecasts.filter(f => f.vis);
        if (activeForecasts.length > 0) {
          this.commit("setForecast", activeForecasts[0].id);
        } else {
          console.log("cannot set default forecast");
        }
      }
    },
    setForecast(state, forecastId) {
      state.activeForecastId = forecastId;
      localStorage.setItem("fy", state.forecasts.find(f => f.id === forecastId).fy);
    },
    toggleSnackbar(state, obj) {
      state.ui.snackbar = obj
    },
    alert(state, payload){
      let obj = {};

      if (typeof payload === "object") {
        obj = payload;
      } else {
        obj.title = "";
        obj.text = payload;
      }

      state.ui.alert = {
        show: !state.ui.alert.show,
        title: obj.title || "",
        text: obj.text || ""
      }
    },
    users(state, arr) {
      arr.forEach(item => item.avatar = process.env.VUE_APP_AVATAR_URL + "/" + item.picture);
      state.users = arr;
    },
    clients(state, arr) {
      arr.forEach(item => {item.logo = item.logo ? process.env.VUE_APP_CLIENTLOGO_URL + "/" + item.logo : null});

      let newBusiness = [];

      state.me.tenants.forEach(t => {
          newBusiness.push({
            id: 0,
            parent_id: null,
            tenant_id: t.id,
            name: trl('client_0'),
            active: 1,
            divisions: []
          })
      })

      state.clients = newBusiness.concat(arr);
    },
    newBusiness(state, arr){
      state.newBusiness = arr;
    },
    vendors(state, arr) {
      state.vendors = arr;
    },
    divisions(state, arr) {
      state.divisions = arr;
    },
    forecasts(state, arr) {
      state.forecasts = arr;
    },
    roles(state, payload) {

      payload.permissions.map(p => p.label = trl(p.property + '__short'));
      state.permissions = payload.permissions;

      //payload.roles.map(item => item.permissions = payload.permissions.filter(p => item.permissions.indexOf(p.property) > -1));
      state.roles = payload.roles;
    },
    attributes(state, payload) {
      state.attributes = payload;
    },
    projects(state, arr) {
      state.projects = arr;
    },
    saveRole(state, obj) {
      let index = state.roles.findIndex(r => r.id === obj.id);
      if (!index) {
        state.roles.push(obj);
      } else {
        for (let key of obj.keys()) {
          let value = obj.get(key);
          state.roles[index][key] = value;
        }
      }
    },
    setExplorer(state, arr) {
      state.explorer.reports = arr;
    }
  },
  actions: {
    async auth({commit}, payload){
      const res = await fetch("/api/auth", {
        method: "POST",
        headers: {
          'Forecast-Version': process.env.VUE_APP_BUILD
        },
        body: JSON.stringify(payload),
      });

      if (!res.ok) {
          localStorage.removeItem("token");
          return res;
      }

      const json = await res.json();

      if(payload.token && !payload.login) {
        this.commit("logout");
        return;
      }

      this.dispatch("login", json);
      return res;
    },
    async login({state}, payload) {

      const res = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + payload.token,
          'Forecast-Version': process.env.VUE_APP_BUILD
        },
        body: JSON.stringify(payload)
      })

      if (!res.ok) {
        this.commit("logout");
        return;
      }

      const me = await res.json();
      me.token = payload.token;

      this.commit("login", me);

      await this.dispatch("req", {route: 'locale/?locale=' + me.locale, mutation: "setLocale"})   
      await this.dispatch("init");

      this.commit("initialized", true);

      if (router.currentRoute.name === "Home") {
        let targetRoute = '/main';

        if (state.redirect && state.redirect.params?.tenant) {
          
          let requestedTenant = state.me.tenants.find(t => t.path === state.redirect.params.tenant)

          if (requestedTenant) {
            this.commit("setTenant", requestedTenant.id);
            targetRoute = state.redirect;
            //reset redirect
            this.commit("redirectFromLogin", false);
          }
        }

        router.push(targetRoute);
      }

      if (payload.rememberMe) {
        this.commit("toggleSnackbar", {
          show: true,
          timeout: 1500,
          text: trl('app_login_return', state.me.firstname)
        });
      }

    },
    async init() {
        const promises = [];
        promises.push(this.dispatch("req", {method: "GET", route: "users?init", mutation: "users"}));
        promises.push(this.dispatch("req", {method: "GET", route: "divisions?init", mutation: "divisions"}));
        promises.push(this.dispatch("req", {method: "GET", route: "roles?init", mutation: "roles"}));
        promises.push(this.dispatch("req", {method: "GET", route: "nb?init", mutation: "newBusiness"}));
        promises.push(this.dispatch("req", {method: "GET", route: "clients?init", mutation: "clients"}));
        promises.push(this.dispatch("req", {method: "GET", route: "vendors?init", mutation: "vendors"}));
        promises.push(this.dispatch("req", {method: "GET", route: "attributes?init", mutation: "attributes"}));
        promises.push(this.dispatch("req", {method: "GET", route: "forecasts?init", mutation: "forecasts"}));
        return Promise.all(promises);
    },
    async saveUser({ state }, payload) {
      await this.dispatch("req", {route: "user", data: payload})
      this.dispatch("req", {method: 'GET', route: 'users', mutation: 'users'})
    },
    async saveSnapshot({state}, payload) {
      await this.dispatch("req", {route: "snapshots", data: payload})
      this.dispatch("req", {method: "GET", route: "forecasts?with_last", mutation: "forecasts"});
    },
    async deleteSnapshot({state}, payload) {
      await this.dispatch("req", {method: 'DELETE', route: "snapshots", data: payload})
      this.dispatch("req", {method: "GET", route: "forecasts", mutation: "forecasts"});
    },
    async req({state}, payload) {     
     
      let method = (payload.method || (!payload.data ? "GET" : (payload.data.id ? "PUT" : "POST"))).toUpperCase();
      let url = '/api/' + payload.route;

      if (["PUT", "DELETE"].indexOf(method) > -1) {
        if (!payload.data?.id){
          console.log("Cannot send request: Missing id");
          return;
        }
        url+= '/' + payload.data.id
      }

      let options = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + state.me.token,
          'Forecast-Version': process.env.VUE_APP_BUILD
        }
      }

      if (payload.data) {
        options.body = JSON.stringify(payload.data);
      }

      const res = await fetch(url, options);


      let text, color, response;
      if (!res.ok) {
        text = res.status + " | " + res.statusText;
        color = "error"
        response = res;
      } else {
        const json = await res.json();
        response = json;
        if (method !== 'GET' && payload.route !== 'budget') {
          text = method + '_' + json.res.result,
          color = json.res.result;
        } else {
          text = "";
        }

        if (payload.action) {
          this.dispatch(payload.action, json);
        }

        if (payload.mutation) {
          this.commit(payload.mutation, json);
        }

      }
      
      if (!res.ok || method !== 'GET' && text !== "") {
        let timeout = res.ok ? 2000 : 5000;
        this.commit("toggleSnackbar", {show: true, text: trl(text), color: color, timeout: timeout})
      }
      return Promise.resolve(response);
    },
    async dwnld({state}, params) {
      let url = "/api/" + params.download + "ToFile";
      let filename = params.filename || "Export";

      delete params.download;
      delete params.filename;

      const res = await fetch(url + '?' + Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&"), {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + state.me.token
          }
      });

      if (!res.ok) {
        this.commit("toggleSnackbar", {show: true, text: trl(res.statusText), color: "error", timeout: 2000})
        return;
      }

      const blob = await res.blob();
  
      let dwnld = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = dwnld;
      a.download = filename + ".csv";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();    
      a.remove();

    }
  },
  getters: {
    me: (state) => state.me,
    current: (state) => state.activeTenant,
    users: (state) => {
      const tenantUsers = [];

      state.users.forEach(u => {
        const tenants = u.tenants.map(t => t.id);
        if (tenants.includes(state.activeTenant.id)) tenantUsers.push(u);
      })

      return tenantUsers;

    },
    clients: (state) => {
      const clients = [];
      state.clients.filter(item => item.tenant_id === state.activeTenant.id).forEach(c => {

        if (c.parent_id && c.active) {
          if (!state.clients.find(cc => cc.id === c.parent_id).active){
            c.active = false;
          }

        }

        c.divisions = null;
        if (c.forecasts && c.forecasts["fcid_" + state.activeForecastId]) {
          c.divisions = c.forecasts["fcid_" + state.activeForecastId];
        }
        clients.push(c)
      })

      return clients;
    },
    clientsWithProjects: (state, getters) => {

      let hasProjects = getters.clients.filter((c) => c.divisions);
      const nb = state.newBusiness.filter(item => item.tenant_id === state.activeTenant.id && item.forecast_id === state.activeForecastId);

      if (nb.length > 0) {
        let newBusinessClient = getters.clients.find(c => c.id === 0);
        newBusinessClient.divisions = nb.map(d => d.division_id);
        hasProjects.unshift(newBusinessClient);
      }

      return hasProjects;
     
    },
    userClients: (state, getters) => (division_id) => {

      let useUserClients = false;
      let myClients = [];

      if (typeof getters.current.permissions.FC_DIVISION_READ === "object") {
          useUserClients = true;
          getters.current.permissions.FC_DIVISION_READ.forEach(p => {
            if (division_id && p.division_id !== division_id) return;
            if (p.clients && p.clients.length > 0) {
              myClients = myClients.concat(p.clients)
              return;
            }
            useUserClients = false;
          })
      }

      if (useUserClients) {
        return getters.clients.filter(item => myClients.indexOf(item.id) > -1);
      }

      return getters.clients;

    },
    vendors: (state) => state.vendors.filter(item => item.tenant_id === state.activeTenant.id),
    userVendors: (state, getters) => getters.vendors.filter(item => !item.deleted_at),
    divisions: (state) => state.divisions.filter(item => item.tenant_id === state.activeTenant.id),
    userDivisions: (state, getters)  => {
      
      let fy = state.forecasts.find(f => f.id === state.activeForecastId )?.fy || new Date().getFullYear();

      let userDivisions = getters.divisions.filter(item => !item.deleted_at && !isLocked(item, fy ));
      if (typeof getters.current.permissions.FC_DIVISION_READ === "object") {
          return userDivisions.filter(item => getters.current.permissions.FC_DIVISION_READ.map(x=> x.division_id).indexOf(item.id) > -1);
      } 

      return userDivisions;

    },
    ud2: (state, getters)  => (fy) => {
      
      let userDivisions = getters.divisions.filter(item => !item.deleted_at && !isLocked(item, fy ));
      if (typeof getters.current.permissions.FC_DIVISION_READ === "object") {
          return userDivisions.filter(item => getters.current.permissions.FC_DIVISION_READ.map(x=> x.division_id).indexOf(item.id) > -1);
      } 

      return userDivisions;

    },
    forecasts: (state, getters) => state.forecasts.filter(item => item.tenant_id === state.activeTenant.id).map((f) => {
        
        let foundActiveSnapshot = null;
        let lastClosedSnapshot = null;

        f.snapshots.forEach((s, index) => { 
          if (s.closed_at) lastClosedSnapshot = index;

          let diff = DateTime.fromSQL(s.due_date,  { zone: process.env.VUE_APP_SQL_TZ }).diff(DateTime.now(), ["days"]);

          let meta = {
            is_due: diff.values.days <= 0,
            allow_commit: !foundActiveSnapshot && !s.closed_at && diff.values.days <= state.budget.commits.days_in_advance, // frühester Hinweis auf Forecast-Abgabe 10 Tage vor dem due_date
            allow_close: !foundActiveSnapshot && !s.closed_at && (diff.values.days <= 0 || s.commits.length === getters.userDivisions.length), // forecast schliessen moeglich, wenn due_date erreicht oder schon alle abgegeben haben
            allow_reopen: false
          }

          let status = [];
         
          
          getters.divisions.forEach(d => {

            const commit = s.commits.find(item => item.division_id === d.id);

            if (commit) {
              status.push(commit);
              return;
            }

            if (isLocked(d, f.fy)) return;
            if (DateTime.fromSQL(d.created_at) > DateTime.fromSQL(s.closed_at ?? s.due_date)) return;
            if (d.deleted_at && DateTime.fromSQL(d.deleted_at) < DateTime.fromSQL(s.closed_at ?? s.due_date)) return;

            if (typeof (s.last === "object")) {
              const last = s.last.find(item => item.division_id === d.id);
              if (last)
                status.push(last)
              else
                status.push({division_id: d.id})
            } else {
              status.push({division_id: d.id})
            }
          })

          if (meta.allow_commit) {
            foundActiveSnapshot = true;
          }

          s.meta = meta;
          s.status = status;

        })

        if (!foundActiveSnapshot && lastClosedSnapshot !== null && f.active){
          f.snapshots[lastClosedSnapshot].meta.allow_reopen = true;
        }
        return f;

    }),
    attributes: (state) => state.attributes.filter(item => item.tenant_id === state.activeTenant.id),
    roles: (state) => state.roles.sort((a,b) => a.name.localeCompare(b.name)),
    permissions: (state) => state.permissions
  }
})