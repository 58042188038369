<template>
    <v-dialog
      v-model="$store.state.ui.alert.show"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="text-h6 primary py-2 white--text mb-6">
          <small>{{ $store.state.ui.alert.title }}</small>
        </v-card-title>
        <v-card-text v-html="$store.state.ui.alert.text"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            @click="dismiss"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "AlertDialog",
    methods:{
        dismiss() {
          this.$store.commit("alert")
        }
    }
  }
</script>
