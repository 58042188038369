<template>
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text>
        <span class="mr-2">{{ me.firstname }} {{ me.lastname }}</span>
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img contain :src="me.avatar"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              >{{ me.firstname }} {{ me.lastname }}</v-list-item-title
            >
            <v-list-item-subtitle>{{ me.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item 
        style="max-width:480px"
        v-for="t in me.tenants" 
        :key="t.id" 
        two-line
        @click="fastswitch(t.id)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ t.name }}</v-list-item-title>
            <v-list-item-subtitle
            class="text-wrap"
              ><strong>{{ t.role }}</strong> – {{
                restrictions(t.permissions)
              }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text color="primary" @click="menu = false">
          {{ trl("close") }}
        </v-btn>
        <v-btn color="primary" to="/logout" @click="dialog = false">
          {{ trl("logout") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
import { trl, getDivisionName } from "@/utils/strings";

export default {
  data: () => ({
    menu: false
  }),
  computed: {
    ...mapGetters(["me", "divisions", "forecasts"]),
  },
  methods: {
    restrictions(permissions) {

      if (this.divisions.length === 0) {
        return;
      }

      if (permissions.FC_DIVISION_READ === true) {
        return "alle Units";
      }

      if (typeof permissions.FC_DIVISION_READ === "object") {
        return permissions.FC_DIVISION_READ.map(
          (x) => this.getDivisionName(x.division_id)
        ).join(", ");
      }
    },
    fastswitch(id){
      
      this.$store.commit("setTenant", id)
      
      // let params = {...this.$route.params}
      // params.tenant = this.me.tenants.find(t => t.id === id).path;

      this.$router.push({name: this.$route.name, params: {tenant: this.me.tenants.find(t => t.id === id).path} })

      // let path = this.$route.path.replace('/' +this.$route.params.tenant +'/', '/' + this.me.tenants.find(t => t.id === id).path + '/');
      // path = path.replace(this.$route.params.id, '');

      // if(path.indexOf('/progress/') > -1) {
      //   path+= this.forecasts.filter(f => f.active)[0].id
      // }

      // this.$router.push(path);
      this.menu = false;

    },
    trl,
    getDivisionName
  },
};
</script>

<style>
</style>