<template>
  
</template>

<script>
export default {
    async created(){
        await this.$store.dispatch("auth", { token: this.$store.state.me.token, login: false });
        this.$router.push("/")
    }
}
</script>

<style>

</style>